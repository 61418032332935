var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tr',{ref:"tableTr",staticClass:"tr-values vs-table--tr",class:[
    `tr-table-state-${_vm.state}`,
    {
      'is-selected': _vm.isSelected,
      selected: _vm.data,
      'is-expand': _vm.maxHeight != '0px',
      activeEdit: _vm.activeEdit,
      hoverFlat: _vm.$parent.hoverFlat,
    },
  ],on:{"dblclick":_vm.dblclicktr,"click":_vm.clicktr}},[(_vm.$parent.multiple || _vm.$slots.expand)?_c('td',{staticClass:"td-check",class:{ 'active-expanded': _vm.expanded }},[(_vm.$parent.multiple)?_c('vs-checkbox',{attrs:{"checked":_vm.isSelected,"size":"small"},on:{"change":_vm.handleCheckbox}}):_vm._e(),(_vm.$slots.expand)?_c('vs-icon',[_vm._v("keyboard_arrow_down")]):_vm._e()],1):_vm._e(),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }